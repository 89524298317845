import classNames from "classnames"
import { Autoplay } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

export type MarqueeProps = {
  items: MarqueItem[]
  className?: string
}

type MarqueItem = {
  id: string
  icon?: React.ReactNode
  text: string
  label?: string
}

export const Marquee = ({ items, className }: MarqueeProps) => (
  <div
    className={classNames(
      className,
      "swiper-wrapper-linear relative h-[58px] w-full overflow-hidden border-y border-neutral-300 md:h-[70px]",
    )}>
    <Swiper
      modules={[Autoplay]}
      spaceBetween={0}
      slidesOffsetBefore={0}
      slidesOffsetAfter={0}
      slidesPerView="auto"
      loop
      centeredSlides
      allowTouchMove={false}
      autoplay={{
        delay: 1,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      }}
      className="!z-0"
      speed={6000}
      watchSlidesProgress>
      {items.map((item, index) => (
        <SwiperSlide key={`marquee-${index}`} className="h-[58px] !w-fit border-l border-neutral-300 md:h-[70px]">
          <div
            key={`marquee-${index}`}
            className="flex h-[58px] items-center justify-center gap-x-2 px-7 md:h-[70px] md:px-[76px]">
            <span id={item.id} className="label3 whitespace-nowrap uppercase text-neutral-800" aria-label={item.label}>
              {item.text}
            </span>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
)
