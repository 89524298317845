import { useBounceGlobalValues, useTranslation, intlNumberFormat } from "@bounce/i18n"
import { Marquee as BaseMarquee } from "@bounce/web-components"

import type { AggregateRating } from "@/libs/bounce/types"

export type MarqueeProps = {
  className?: string
  price?: string
  aggregateRating: AggregateRating
}

export const Marquee = ({ price, className, aggregateRating }: MarqueeProps) => {
  const { t, i18n } = useTranslation()
  const bounceGlobals = useBounceGlobalValues()

  const { format: ratingFormat } = intlNumberFormat({ locale: i18n.language, options: { maximumFractionDigits: 1 } })
  const { format: countFormat } = intlNumberFormat({ locale: i18n.language, options: {} })

  const items = [
    {
      id: "marquee-review-rating",
      text: t("cmp.marqueeLuggageStorage.ratedV4", "Rated {{appRating}} ({{numAppReviews}}+ reviews)", {
        appRating: ratingFormat(aggregateRating.rating),
        numAppReviews: countFormat(aggregateRating.count),
      }),
      label: t("cmp.marqueeLuggageStorage.ratedLabel", "{{appRating}} of 5 stars. {{numAppReviews}} reviews", {
        appRating: ratingFormat(aggregateRating.rating),
        numAppReviews: countFormat(aggregateRating.count),
      }),
    },
    {
      id: "marquee-protection",
      text: t("cmp.marqueeLuggageStorage.insuranceV3", "{{insurancePriceText}} protection", {
        insurancePriceText: bounceGlobals.bounceShieldProtection,
      }),
    },
    {
      id: "marquee-cancellation",
      text: t("cmp.marqueeLuggageStorage.cancellationV2", "Free cancellation"),
    },
    {
      id: "marquee-locations",
      text: t("cmp.marqueeLuggageStorage.locationsV4", "{{numLocations}}+ locations globally", {
        numLocations: bounceGlobals.numLocations,
      }),
    },
  ]

  if (!!price) {
    items.unshift({
      id: "marquee-price-range",
      text: t("cmp.marqueeLuggageStorage.priceV2", {
        defaultValue: "from {{price}}/day",
        price,
      }),
      label: t("cmp.marqueeLuggageStorage.priceLabel", "luggage storage from {{price}}/day", {
        price,
      }),
    })
  }

  return <BaseMarquee items={items} className={className} />
}
