import classNames from "classnames"

import { useTranslation } from "@bounce/i18n"
import { Button, Separator } from "@bounce/web-components"

import { BagPicker } from "./BagPicker"
import { DatePicker } from "./DatePicker"
import { useSearchBar } from "./useSearchBar"
import { useStickyCTA } from "../../../contexts/StickyCTAContext"
import { AddressField } from "@/components/Generic/AddressField/AddressField"

export type SearchBarProps = {
  ctaName: string
  href?: string
  initialQuery?: string
  placeholder?: string
  cta?: string
  couponCode?: string
  showPickers?: boolean
  showAddressField?: boolean
  className?: string
  divClassName?: string
  LeftIcon?: React.FC<{ className: string }>
  RightIcon?: React.FC<{ className: string }>
  iconClassName?: string
  anchorTitle?: string
}

export const SearchBar = ({
  href,
  initialQuery = "",
  placeholder,
  couponCode,
  cta,
  LeftIcon,
  RightIcon,
  iconClassName,
  anchorTitle,
  showPickers = true,
  showAddressField = true,
  ctaName,
  className,
  divClassName,
}: SearchBarProps) => {
  const { t } = useTranslation()
  const { ref: stickyCTARef } = useStickyCTA()
  const {
    searchUrl,
    onSearch,
    setQuery,
    onSelectRecommendation,
    numberOfBags,
    checkInDate,
    handleUpdateBags,
    handleUpdateCheckInDate,
  } = useSearchBar({ initialQuery, couponCode, ctaName })

  return (
    <form onSubmit={onSearch} className={classNames("flex w-full flex-col", className)}>
      <div className={classNames("relative flex w-full rounded-full border border-neutral-300", divClassName)}>
        {showAddressField && (
          <AddressField
            defaultValue={initialQuery}
            placeholder={placeholder || t("cmp.searchBar.placeholder", "Search place or address")}
            onChange={setQuery}
            onSelectRecommendation={onSelectRecommendation}
          />
        )}
        {showPickers && (
          <div className="hidden items-center md:flex">
            <Separator direction="vertical" className="h-7" />
            <DatePicker checkInDate={checkInDate} handleUpdateCheckInDate={handleUpdateCheckInDate} />
            <Separator direction="vertical" className="h-7" />
            <BagPicker numberOfBags={numberOfBags} handleUpdateBags={handleUpdateBags} />
          </div>
        )}
      </div>
      {showPickers && (
        <div
          className={classNames(
            "mt-4 flex h-12 w-full flex-row items-center rounded-full border border-neutral-300 md:hidden",
          )}>
          <DatePicker checkInDate={checkInDate} handleUpdateCheckInDate={handleUpdateCheckInDate} className="flex-1" />
          <Separator direction="vertical" className="h-7" />
          <BagPicker numberOfBags={numberOfBags} handleUpdateBags={handleUpdateBags} className="flex-1 justify-end" />
        </div>
      )}
      <div ref={stickyCTARef}>
        <Button
          href={href || searchUrl}
          LeftIcon={LeftIcon}
          RightIcon={RightIcon}
          iconClassName={iconClassName}
          rel="nofollow"
          title={anchorTitle}
          onClick={onSearch}
          color="primary"
          className={classNames("mt-4 w-full max-w-full md:h-16 md:w-fit")}
          textClassName="line-clamp-1"
          data-chromatic="ignore"
          suppressHydrationWarning>
          {cta || t("cmp.searchBar.defaultCtaV1", "Find closest locations")}
        </Button>
      </div>
    </form>
  )
}
